import app from './App/App';
import home from './Home/Home';
import videos from './Videos/Videos';
import demos from './Demos/Demos';
import largeHierarchy from './LargeHierarchy/Loadable';
import crossBranchAlignment from './CrossBranchAlignment/Loadable';
import dynamicLoading from './DynamicLoading/Loadable';
import orgEditor from './OrgEditor/Loadable';
import verticalLayout from './VerticalLayout/Loadable';
import crossTeamGroup from './CrossTeamGroup/Loadable';
import highlightAnnotations from './HighlightAnnotations/Loadable';
import familyChartWithAnnotations from './FamilyChartWithAnnotations/Loadable';
import familyChartItemsOrdering from './FamilyChartItemsOrdering/Loadable';
import dependencies from './Dependencies/Loadable';
import patents from './Patents/Loadable';
import financialOwnership from './FinancialOwnership/Loadable';
import mutualFinancialOwnership from './MutualFinancialOwnership/Loadable';
import techTree from './TechTree/Loadable';
import partners from './Partners/Loadable';
import useCases from './UseCases/UseCases';
import howToUse from './UseCases/Loadable';
import reactUseCases from './ReactUseCases/ReactUseCases';
import reactHowToUse from './ReactUseCases/Loadable';
import angularUseCases from './AngularUseCases/AngularUseCases';
import angularHowToUse from './AngularUseCases/Loadable';
import reference from './Reference/Loadable';
import apiReference from './Reference/ApiReference';
import changelog from './Changelog/Changelog';
import downloads from './Downloads/Downloads';
import license from './License/License';
import contact from './Contact/Contact';
import notFound from './NotFound/NotFound';

export const App = app;
export const Home = home;
export const Videos = videos;
export const Demos = demos;
export const LargeHierarchy = largeHierarchy;
export const CrossBranchAlignment = crossBranchAlignment;
export const DynamicLoading = dynamicLoading;
export const OrgEditor = orgEditor;
export const VerticalLayout = verticalLayout;
export const CrossTeamGroup = crossTeamGroup;
export const HighlightAnnotations = highlightAnnotations;
export const FamilyChartWithAnnotations = familyChartWithAnnotations;
export const FamilyChartItemsOrdering = familyChartItemsOrdering;
export const Dependencies = dependencies;
export const Patents = patents;
export const FinancialOwnership = financialOwnership;
export const MutualFinancialOwnership = mutualFinancialOwnership;
export const TechTree = techTree;
export const Partners = partners;
export const ReactUseCases = reactUseCases;
export const UseCases = useCases;
export const AngularUseCases = angularUseCases;
export const ReactHowToUse = reactHowToUse;
export const HowToUse = howToUse;
export const AngularHowToUse = angularHowToUse;
export const ApiReference = apiReference;
export const Reference = reference;
export const Changelog = changelog;
export const Downloads = downloads;
export const License = license;
export const Contact = contact;
export const NotFound = notFound;

